import { ReactElement, memo, useRef } from 'react';
import { CategoryIcon } from '@/shared/images/categories';
import Link from 'next/link';
import { TTopAttractionsProps } from '../domain/TopAttractions.domain';
import * as S from './TopAttractions.styles';
import { LightArrows, SliderDots } from '@/common/ui';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { DefaultIconForMissedCategories } from '../../images/categories/DefaultIconForMissedCategories';

export const TopAttractions = ({
    featuredCategories,
    destinationName,
    title,
    description,
    activeIds,
    onClick,
}: TTopAttractionsProps): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
        dotActive,
    } = useCarousel({ ref: scrollRef, countItems: featuredCategories.length });

    return (
        <S.Wrapper>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    <S.Header>
                        <S.Title data-test-id="attraction-title">
                            {title || `Top ${destinationName} Attractions, Tours & Rentals`}
                        </S.Title>
                        <S.ArrowBlock>
                            {featuredCategories.length > 4 && (
                                <LightArrows
                                    onLeft={() => {
                                        onClickHandler(DIRECTION.prev);
                                        //setPrevBtnIsActive(false);
                                    }}
                                    onRight={() => {
                                        onClickHandler(DIRECTION.next);
                                        //setNextBtnIsActive(false);
                                    }}
                                    leftOff={!prevBtnIsActive}
                                    rightOff={!nextBtnIsActive}
                                />
                            )}
                        </S.ArrowBlock>
                    </S.Header>
                    {description && <S.Description>{description}</S.Description>}
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {featuredCategories.map((slide) => {
                                    const Icon =
                                        CategoryIcon[slide.slug || slide.name.replace(/\s/g, '-')];
                                    const isActive = activeIds?.includes(slide.id);

                                    const content = (
                                        <S.Card className="card" isActive={isActive}>
                                            {isActive && <S.CloseButton />}
                                            <S.InnerCard>
                                                {Icon ? (
                                                    <Icon />
                                                ) : (
                                                    <DefaultIconForMissedCategories />
                                                )}
                                                <S.CategoryTitle>{slide.name}</S.CategoryTitle>
                                            </S.InnerCard>
                                        </S.Card>
                                    );

                                    if (onClick) {
                                        return (
                                            <div key={slide.id} onClick={() => onClick(slide.id)}>
                                                {content}
                                            </div>
                                        );
                                    }

                                    return (
                                        <Link
                                            href={slide.url}
                                            passHref
                                            key={slide.id}
                                            prefetch={false}
                                        >
                                            {content}
                                        </Link>
                                    );
                                })}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && featuredCategories.length > 2 && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && featuredCategories.length > 2 && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                    {!!withDot && (
                        <S.DotsSlider>
                            {[...Array(featuredCategories.length).keys()].map((el) => {
                                return (
                                    <SliderDots isActive={el === Math.ceil(dotActive)} key={el} />
                                );
                            })}
                        </S.DotsSlider>
                    )}
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};

export default memo(TopAttractions);
